import * as React from 'react'
import { SP, PC } from '../components/utils/media-query'
import Header from '../components/organisms/Header'
import Button from '../components/atoms/TheButton'
import HeadingIllust from '../components/atoms/HeadingIlust'
import Footer from '../components/organisms/Footer'

const styles = require('scss/ceoMessage.module.scss')

const topMessage = (
  <pre>
    <SP>
      {`いわゆる「一般的な会社」では、実にさまざまな能力が
求められるように思います。
「早起きをする力」や、「上司と空気を読んで会話をす
る」など。
一見これらは「社会人の常識」に見えますが、一方で、
こういった能力が「常識」だと捉えられる世の中の空気
に「働きにくさ」を感じている方もいらっしゃるのでは
ないでしょうか。

素晴らしいスキルを持っているにもかかわらず、この
「働きにくさ」によって活躍の芽が摘まれるエンジニア
を目の当たりにするたび、疑問符が浮かびました。

「こうまでして凝り固まったルールを強いる必要性はあ
るのか？」と。`}
    </SP>
    <PC>
      {`いわゆる「一般的な会社」では、実にさまざまな能力が求めら
れるように思います。
「早起きをする力」や、「上司と空気を読んで会話をする」な
ど。
一見これらは「社会人の常識」に見えますが、一方で、こうい
った能力が「常識」だと捉えられる世の中の空気に「働きにく
さ」を感じている方もいらっしゃるのではないでしょうか。

素晴らしいスキルを持っているにもかかわらず、この「働きに
くさ」によって活躍の芽が摘まれるエンジニアを目の当たりに
するたび、疑問符が浮かびました。

「こうまでして凝り固まったルールを強いる必要性はあるの
か？」と。`}
    </PC>
  </pre>
)

const middleMessage = (
  <pre>
    <SP>
      {`人がメキメキと長所を伸ばしながら自走し続ける光景ほ
ど、ワクワクするものはないと思っています。
そんな光景が溢れる会社を共に作れる人たちと、一緒に
働きたい。これが、創業に至った最大のきっかけです。

そんな会社を実現するために弊社は、長所を活かせる組
織体制にしています。
「良いものを作るために妥協しない」「細部までミス
を逃さない」「効率にとことんこだわる」
こういった長所が備わっているメンバーが弊社にはいま
すが、それぞれが存分に長所を発揮することで
「ワクワクできる最高の組織」が作れると確信している
とともに、最善の価値提供ができることを信じて止みま
せん。

「日常に、ちょっと楽しい一コマを。」
社名の由来でもあるこのビジョンは、顧客だけでなく、
一緒に働いてくださるメンバーにも向けたメッセージな
のです。`}
    </SP>
    <PC>
      {`人がメキメキと長所を伸ばしながら自走し続ける光景ほど、ワクワクするものはないと思っています。
そんな光景が溢れる会社を共に作れる人たちと、一緒に働きたい。これが、創業に至った最大のきっかけです。

そんな会社を実現するために弊社は、長所を活かせる組織体制にしています。
「良いものを作るために妥協しない」「細部までミスを逃さない」「効率にとことんこだわる」
こういった長所が備わっているメンバーが弊社にはいますが、それぞれが存分に長所を発揮することで
「ワクワクできる最高の組織」が作れると確信しているとともに、最善の価値提供ができることを信じて止みません。

「日常に、ちょっと楽しい一コマを。」
社名の由来でもあるこのビジョンは、顧客だけでなく、一緒に働いてくださるメンバーにも向けたメッセージなのです。`}
    </PC>
  </pre>
)

const bottomMessage = (
  <h3>
    <SP>{`ニチコマでは、
一緒に働くメンバーを募集しています。`}</SP>
    <PC>{`ニチコマでは、一緒に働くメンバーを募集しています。`}</PC>
  </h3>
)

const title = '代表メッセージ'
const pageName = 'CEO Message'

const CEOMessage = () => {
  const array = [0, 1]
  const images = array.map((index) => {
    const spImage = require(`images/sp/ceo_message_${
      index === 0 ? `${index}.png` : `${index}.jpg`
    }`)
    const pcImage = require(`images/pc/ceo_message_${
      index === 0 ? `${index}.png` : `${index}.jpg`
    }`)

    return {
      sp: spImage,
      pc: pcImage,
    }
  })

  return (
    <section className={styles.ceoMessage}>
      <Header />
      <div>
        <SP>
          <HeadingIllust
            image={images[0].sp}
            title={title}
            pageName={pageName}
            top={-36}
          />
        </SP>
        <PC>
          <HeadingIllust
            image={images[0].pc}
            title={title}
            pageName={pageName}
            top={-16}
          />
        </PC>
      </div>
      <div className={styles.contentsWrapper}>
        <PC>
          <div className={styles.topWrapper}>
            <div>
              <img src={images[1].pc} alt="CEO" />
              <div className={styles.profile}>
                <p>
                  代表
                  <br />
                  <span>渡辺健太</span>
                </p>
              </div>
            </div>
            {topMessage}
          </div>
          <div className={styles.middleWrapper}>
            {middleMessage}
          </div>
        </PC>
        <SP>
          <div className={styles.topWrapper}>
            {topMessage}
            {middleMessage}
          </div>
          <div className={styles.middleWrapper}>
            <img src={images[1].sp} alt="CEO" />
            <div className={styles.profile}>
              <p>
                代表
                <br />
                <span>渡辺健太</span>
              </p>
            </div>
          </div>
        </SP>
      </div>
      <hr />
      <div className={styles.bottomWrapper}>
        {bottomMessage}
        <SP>
          <Button
            type="ellipse"
            icon
            to="/recruit"
            size="responsive"
          >
            採用ページへ
          </Button>
        </SP>
        <PC>
          <Button
            type="square"
            size="small"
            icon
            to="/recruit"
          >
            採用ページへ
          </Button>
        </PC>
      </div>
      <Footer />
    </section>
  )
}

export default CEOMessage
