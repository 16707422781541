import * as React from 'react'
const styles = require('scss/headingIllust.module.scss')

export interface HeadingIllustProps {
  image?: string
  webP?: string
  title: string
  pageName: string
  top?: number
  width?: number
}

const HeadingIllust = (props: HeadingIllustProps) => {
  return (
    <div className={styles.headingImage}>
      <div className={styles.bg}>
        <div className={styles.contents}>
          <div className={styles.text}>
            <h1>{props.title}</h1>
            <span />
            <p>{props.pageName}</p>
          </div>
          {props.image && (
            <div
              className={styles.imageBox}
              style={{
                top: `${props.top ? props.top : '-20'}px`,
                width: props.width
                  ? props.width
                  : undefined,
              }}
            >
              <picture>
                <source
                  type="image/webp"
                  srcSet={props.webP}
                />
                <img src={props.image} alt={props.title} />
              </picture>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default HeadingIllust
